export default{
    data(){
        return{
            
        }
    },
    mounted(){
        
    },
    methods:{
        onBack(type){
            let { from } = this.$route.query;
            if(['wxPay'].includes(from)){ // 从支付页面过来
                switch (type) {
                    case 1: this.$router.go(-3);break;
                    case 2: this.$router.go(-2);break;
                } 
            }else{
                switch (type) {
                    case 1: this.$router.go(-2);break;
                    case 2: this.$router.go(-1);break;
                }
            }
        }
    }
}